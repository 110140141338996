<template>
  <div>
    <div class='flex justify-center'>
      <div class='rea flex items-center justify-center px-8 mx-4 rounded-full cursor-pointer'
        v-for='index in Math.ceil(totalSize / pageSize)'
        :class='{ "active": index === currentPage }'
        :key='index'
        @click='$emit("update:currentPage", index)'>
        {{ index }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      currentPage: Number,
      pageSize: Number,
      totalSize: Number
    }
  }
</script>

<style lang='scss' scoped>
  .rea {
    &:hover {
      @apply text-primary;
    }

    &.active {
      width: 32px;
      height: 32px;
      @apply
        text-white
        bg-primary;
    }
  }
</style>
