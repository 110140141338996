<template>
  <div>
    <div class='plc grid'>
      <plan-image-component class='tmb overflow-hidden mr-24 rounded'
        :plan='plan'/>
      <div class='ida'>
        <div class='w-full'>
          <div class='text-xl'>{{ plan.name }}</div>
          <div class='flex mt-16'>
            <i class='icon text-gray-700'>signal_cellular_alt</i>
            <div class='ml-16'>{{ plan.networkType }}</div>
          </div>
        </div>
        <badge-element class='cty inline-flex items-center whitespace-no-wrap cursor-pointer'
          v-if='plan.countries.length > 1'
          @click.native='$refs.countriesDialog.open()'>
          <i class='icon text-sm text-white'>public</i>
          <div class='ml-8 text-sm'>{{ plan.countries.length }} {{ $tc('common.country', plan.countries.length) }}</div>
        </badge-element>
      </div>
      <div class='otp mt-24'>
        <div class='flex justify-between'>
          <div class='flex'>
            <i class='icon text-gray-700'>language</i>
            <div class='ml-16'>{{ $t('common.data') }}</div>
          </div>
          <div v-if='plan.data.value > 0'>{{ plan.data.value }} {{ plan.data.unit }}</div>
          <div v-else>{{ $t('common.unlimited') }}</div>
        </div>
        <div class='flex justify-between pt-8 mt-8 border-gray-300 border-t'>
          <div class='flex'>
            <i class='icon text-gray-700'>date_range</i>
            <div class='ml-16'>{{ $t('common.validity') }}</div>
          </div>
          <div>{{ plan.period.value }} {{ plan.period.unit }}</div>
        </div>
        <router-link class='block mt-24'
          :to='{ name: "plan", params: { slug: plan.slug } }'>
          <button-element class='is-block is-outlined'>
            <price-text-component
              :value='plan.price.value'
              :currency='plan.price.currency'/>
          </button-element>
        </router-link>
      </div>
    </div>
    <dialog-element ref='countriesDialog'>
      <div class='p-24'>
        <div class='text-lg'>{{ $t('common.supported-countries') }}</div>
        <div class='flex items-center mt-24'
          v-for='country in plan.countries'
          :key='country.code'>
          <img class='w-24'
            :src='`flags/countries/${country.code.toLowerCase()}.svg`'>
          <div class='ml-16'>{{ country.name }}</div>
        </div>
      </div>
      <template #actions>
        <div class='py-16 px-24 font-bold text-center hover:text-primary border-gray-300 border-t cursor-pointer'
          @click='$refs.countriesDialog.close()'>
          {{ $t('common.close') }}
        </div>
      </template>
    </dialog-element>
  </div>
</template>

<script>
  import PlanImage from '@/components/plan-image'
  import PriceText from '@/components/price-text'

  export default {
    components: {
      'plan-image-component': PlanImage,
      'price-text-component': PriceText
    },

    props: {
      plan: Object
    }
  }
</script>

<style lang='scss' scoped>
  .plc {
    grid-template-columns: auto minmax(0, 1fr);
  }

  .tmb {
    grid-area: 1 / 1 / 2 / 2;
    height: 90px;
  }

  .ida {
    grid-area: 1 / 2 / 2 / 3;
  }

  .cty {
    @apply mt-16;
  }

  .otp {
    grid-area: 2 / 1 / 3 / 3;
  }

  @screen desktop {
    .tmb {
      grid-area: 1 / 1 / 3 / 2;
      height: 240px;
    }

    .ida {
      @apply
        flex
        items-start;
    }

    .cty {
      @apply mt-4;
    }

    .otp {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
</style>
