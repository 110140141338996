<template>
  <div>
    <skeleton-loader-component class='mt-24'
      :loading='!planGroups'>
      <template v-if='planGroups && planGroups.length'>
        <div>
          <collapse-element class='may bg-white rounded shadow-sm'
            v-for='group in planGroups'
            :key='group.region.code'>
            <template #header>
              <div class='flex items-center py-16 px-24 cursor-pointer'
                @click='scrollTo($event.target)'>
                <img class='w-24'
                  :src='`flags/regions/${group.region.code.toLowerCase()}.png`'>
                <div class='ml-24'>{{ group.region.name }}</div>
              </div>
            </template>
            <div class='p-24 border-gray-300 border-t'>
              <plan-card-component class='ard'
                v-for='plan in group.plans'
                :key='plan.slug'
                :plan='plan'/>
            </div>
          </collapse-element>
        </div>
      </template>
      <template v-else>
        <div class='my-48 text-center text-gray-500'>{{ $t('pages.home.3c610a80') }}</div>
      </template>
    </skeleton-loader-component>
  </div>
</template>

<script>
  import filter from 'lodash/fp/filter'
  import flow from 'lodash/fp/flow'
  import groupBy from 'lodash/fp/groupBy'
  import sortBy from 'lodash/fp/sortBy'
  import transform from 'lodash/fp/transform'
  import PlanCard from '@/components/plan-card'
  import SkeletonLoader from '@/components/skeleton-loader'
  import scrollMixin from '@/mixins/scroll'

  export default {
    components: {
      'plan-card-component': PlanCard,
      'skeleton-loader-component': SkeletonLoader
    },

    mixins: [scrollMixin],

    props: {
      search: String
    },

    computed: {
      plans () {
        return this.$store.state.plans.plans
      },

      planGroups () {
        if (!this.plans) return null

        let groups = flow(
          filter(plan => plan.region),
          groupBy('region.code'),
          transform((groups, items) => {
            groups.push({
              region: items[0].region,
              plans: items
            })
          }, []),
          sortBy('region.name')
        )(this.plans)

        if (this.search) {
          groups = groups.filter(group => (
            new RegExp(this.search, 'i').test(group.region.name)
          ))
        }

        return groups
      }
    }
  }
</script>

<style lang='scss' scoped>
  .may {
    &:not(:first-child) {
      @apply mt-16;
    }
  }

  .ard {
    &:not(:first-child) {
      @apply mt-48;
    }
  }
</style>
