<template>
  <div>
    <top-navigation-component/>
    <div class='py-48 px-24 text-center bg-white'>
      <div class='con m-auto'>
        <img class='lgo m-auto'
          src='images/d386.png'>
        <div class='mt-48 text-lg text-gray-700'>{{ $t('pages.home.807dd66e') }}</div>
        <div class='ogt mx-auto mt-24'>
          <router-link class='block'
            :to='{ name: "how-esim-works" }'>
            <button-element class='is-block'>{{ $t('pages.home.f8093bf1') }}</button-element>
          </router-link>
          <router-link class='block mt-8'
            :to='{ name: "compatible-devices" }'>
            <button-element class='is-block bg-secondary hover:bg-secondary-dark'>{{ $t('pages.home.b7b61d35') }}</button-element>
          </router-link>
        </div>
      </div>
    </div>
    <div class='p-24'>
      <div class='con m-auto'>
        <div class='flex'>
          <div class='flex flex-grow items-center py-8 px-24 bg-white rounded shadow-sm'>
            <i class='icon text-gray-700'>search</i>
            <input-element class='sip ml-16 w-full'
              type='search'
              :placeholder='$t("pages.home.acf2ac01")'
              @input='updateSearch'/>
          </div>
          <money-exchange-svg class='moe ml-8 p-8 text-gray-700 bg-white rounded shadow-sm cursor-pointer fill-current'
            @click='$refs.currenciesDialog.open()'/>
        </div>
        <div class='flex mt-24 font-bold text-center shadow-sm cursor-pointer'>
          <div class='tbb flex flex-col justify-center py-16 px-24 w-full rounded-l transition duration-200'
            :class='{ "active": tab === "countries" }'
            @click='tab = "countries"'>
            {{ $t('pages.home.75015252') }}
          </div>
          <div class='tbb flex flex-col justify-center py-16 px-24 w-full rounded-r transition duration-200'
            :class='{ "active": tab === "regions" }'
            @click='tab = "regions"'>
            {{ $t('pages.home.175d719b') }}
          </div>
        </div>
        <countries-section
          v-if='tab === "countries"'
          :search='search'/>
        <regions-section
          v-if='tab === "regions"'
          :search='search'/>
      </div>
    </div>
    <dialog-element ref='currenciesDialog'>
      <div class='p-24 pb-0'>
        <div class='text-lg'>{{ $t('pages.home.58e262ab') }}</div>
      </div>
      <template v-if='supportedCurrencies'>
        <div class='flex flex-wrap gap-8 p-16'>
          <div class='tcm p-8 rounded cursor-pointer'
            v-for='currency in supportedCurrencies'
            :class='{ "active": currency.code === userCurrency }'
            :key='currency.code'
            @click='changeCurrency(currency.code)'>
            <div>{{ currency.code }}</div>
            <div class='cyc text-sm'>{{ currency.name }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class='flex justify-center p-24'>
          <spinner-element class='text-gray-500'/>
        </div>
      </template>
      <template #actions>
        <div class='py-16 px-24 font-bold text-center hover:text-primary border-gray-300 border-t cursor-pointer'
          @click='$refs.currenciesDialog.close()'>
          {{ $t('common.close') }}
        </div>
      </template>
    </dialog-element>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import MoneyExchange from '@/assets/graphics/money-exchange.svg'
  import BottomNavigation from '@/components/bottom-navigation'
  import TopNavigation from '@/components/top-navigation'
  import Countries from './countries'
  import Regions from './regions'

  export default {
    name: 'home-page',

    components: {
      'bottom-navigation-component': BottomNavigation,
      'top-navigation-component': TopNavigation,
      'countries-section': Countries,
      'regions-section': Regions,
      'money-exchange-svg': MoneyExchange
    },

    data: () => ({
      tab: 'countries',
      search: ''
    }),

    computed: {
      userCurrency () {
        return this.$store.state.app.currency
      },

      supportedCurrencies () {
        return this.$store.state.settings.supportedCurrencies
      }
    },

    mounted () {
      this.$store.dispatch('plans/fetchPlans')
    },

    methods: {
      updateSearch (value) {
        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
          this.search = value
        }, 400)
      },

      changeCurrency (currency) {
        localStorage.setItem('currency', currency)
        this.$store.dispatch('app/changeCurrency', { currency })
        this.$refs.currenciesDialog.close()
      }
    }
  }
</script>

<style lang='scss' scoped>
  .con {
    max-width: 640px;
  }

  .lgo {
    width: 240px;
  }

  .ogt {
    max-width: 360px;
  }

  .sip::v-deep .inp {
    @apply
      px-0
      border-0;
  }

  .moe {
    width: 56px;
    height: 56px;
  }

  .tbb {
    @apply bg-white;

    &:hover {
      @apply
        text-white
        bg-primary-dark;
    }

    &.active {
      @apply
        text-white
        bg-primary;
    }
  }

  .tcm {
    width: calc(50% - 8px);

    @apply
      border-transparent
      border-2;

    &:hover {
      @apply border-primary;
    }

    &.active {
      @apply
        text-white
        bg-primary;
    }
  }

  .cyc {
    @apply text-gray-500;

    .tcm.active & {
      @apply text-white;
    }
  }
</style>
