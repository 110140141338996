<template>
  <div>
    <skeleton-loader-component class='mt-24'
      :loading='!planGroups'>
      <template v-if='planGroups && planGroups.length'>
        <div>
          <collapse-element class='may bg-white rounded shadow-sm'
            v-for='group in subPlanGroups'
            :key='group.country.code'>
            <template #header>
              <div class='flex items-center py-16 px-24 cursor-pointer'
                @click='scrollTo($event.target)'>
                <img class='w-24'
                  :src='`flags/countries/${group.country.code.toLowerCase()}.svg`'>
                <div class='ml-24'>{{ group.country.name }}</div>
              </div>
            </template>
            <div class='p-24 border-gray-300 border-t'>
              <plan-card-component class='ard'
                v-for='plan in group.plans'
                :key='plan.slug'
                :plan='plan'/>
            </div>
          </collapse-element>
        </div>
        <button-element class='is-block p-16 mt-24'
          v-if='onlyPopular'
          @click.native='onlyPopular = false'>
          {{ $t('common.all-countries') }}
        </button-element>
        <pagination-component class='mt-24'
          v-if='!onlyPopular'
          :current-page.sync='currentPage'
          :page-size='pageSize'
          :total-size='planGroups.length'/>
      </template>
      <template v-else>
        <div class='my-48 text-center text-gray-500'>{{ $t('pages.home.638d30ad') }}</div>
      </template>
    </skeleton-loader-component>
  </div>
</template>

<script>
  import flow from 'lodash/fp/flow'
  import map from 'lodash/fp/map'
  import sortBy from 'lodash/fp/sortBy'
  import Pagination from '@/components/pagination'
  import PlanCard from '@/components/plan-card'
  import SkeletonLoader from '@/components/skeleton-loader'
  import scrollMixin from '@/mixins/scroll'

  export default {
    components: {
      'pagination-component': Pagination,
      'plan-card-component': PlanCard,
      'skeleton-loader-component': SkeletonLoader
    },

    mixins: [scrollMixin],

    props: {
      search: String
    },

    data: () => ({
      onlyPopular: true,
      currentPage: 1,
      pageSize: 50
    }),

    computed: {
      popularCountries () {
        return this.$store.state.settings.popularCountries
      },

      plans () {
        return this.$store.state.plans.plans
      },

      planGroups () {
        if (!this.plans || !this.popularCountries) return null

        let codes = new Set()
        let countries = {}

        let isInclude = function (code, countries) {
          return countries.find(country => country.code === code)
        }

        for (let plan of this.plans) {
          for (let country of plan.countries) {
            codes.add(country.code)
            countries[country.code] = country
          }
        }

        if (this.onlyPopular) {
          for (let code of codes) {
            if (!isInclude(code, this.popularCountries)) {
              codes.delete(code)
            }
          }
        }

        let groups = flow(
          map(code => ({
            country: countries[code],
            plans: this.plans.filter(plan => isInclude(code, plan.countries))
          })),
          sortBy('country.name')
        )([...codes])

        if (this.search) {
          groups = groups.filter(group => (
            new RegExp(this.search, 'i').test(group.country.name)
          ))
        }

        return groups
      },

      subPlanGroups () {
        if (this.onlyPopular) return this.planGroups

        let start = (this.currentPage - 1) * this.pageSize
        let end = start + this.pageSize
        return this.planGroups.slice(start, end)
      }
    },

    watch: {
      search (search) {
        if (search) {
          this.onlyPopular = false
        }
      }
    }
  }
</script>

<style lang='scss' scoped>
  .may {
    &:not(:first-child) {
      @apply mt-16;
    }
  }

  .ard {
    &:not(:first-child) {
      @apply mt-48;
    }
  }
</style>
